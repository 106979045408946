import { ref } from '@vue/composition-api'

const defaultState = () => ({
  distritos: ref([]),
  concelhos: ref([]),
  freguesias: ref([]),
  ruas: ref([]),
  floors: ref([]),
})

export default defaultState
