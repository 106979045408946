import api from '@core-custom/api/api'
import apiConfig from '@core-custom/api/apiConfig'
import i18n from '@/libs/i18n'
import defaultState from './defaultState'

export default {
  namespaced: true,
  state: defaultState,
  getters: {
    distritos: state => state.distritos.value,
    concelhos: state => state.concelhos.value,
    freguesias: state => state.freguesias.value,
    ruas: state => state.ruas.value,
    floors: state => state.floors.value,
  },
  mutations: {
    resetState(state) {
      Object.assign(state, defaultState)
    },
    setDistritos(state, payload) {
      state.distritos.value = []
      if (Array.isArray(payload)) {
        state.distritos.value = payload
      }
    },
    setConcelhos(state, payload) {
      state.concelhos.value = []
      if (Array.isArray(payload)) {
        state.concelhos.value = payload
      }
    },
    setFreguesias(state, payload) {
      state.freguesias.value = []
      if (Array.isArray(payload)) {
        state.freguesias.value = payload
      }
    },
    setRuas(state, payload) {
      state.ruas.value = []
      state.ruas.value.push({
        id: 'MANUAL',
        rua: i18n.t('Adicionar morada manualmente'),
      })

      if (Array.isArray(payload)) {
        state.ruas.value = state.ruas.value.concat(payload)
      }
    },
    setFloors(state, payload) {
      state.floors.value = []
      if (Array.isArray(payload)) {
        state.floors.value = payload
      }
    },
  },
  actions: {
    // Obter distritos
    async getAllDistritos({ commit }) {
      return new Promise((resolve, reject) => {
        api.post(`${apiConfig.url_base_api}distritos/getAll`)
          .then(response => {
            try {
              if (typeof response.data.distritos === 'object') {
                commit('setDistritos', response.data.distritos)
                resolve(true)
              } else {
                reject(new Error(i18n.t('Problema ao carregar os distritos')))
              }
            } catch (error) {
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                reject(new Error(i18n.t('Problema ao carregar os distritos')))
              }
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao carregar os distritos')))
            }
          })
      })
    },

    // Obter concelhos pelo distrito
    async getAllConcelhosByDistrito({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const formData = new FormData()

        if (Array.isArray(payload.distrito)) {
          formData.append('group', true)
          payload.distrito.forEach(row => {
            formData.append('sw016s04[]', row.id)
          })
        } else {
          formData.append('sw016s04', payload.distrito || '')
        }

        api.post(`${apiConfig.url_base_api}concelho/getAllByDistrito`, formData)
          .then(response => {
            try {
              if (typeof response.data.concelhos === 'object') {
                commit('setConcelhos', response.data.concelhos)
                resolve(true)
              } else {
                reject(new Error(i18n.t('Problema ao carregar os concelhos')))
              }
            } catch (error) {
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                reject(new Error(i18n.t('Problema ao carregar os concelhos')))
              }
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao carregar os concelhos')))
            }
          })
      })
    },

    // Obter freguesias pelo concelho
    async getAllFreguesiasByConcelho({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const formData = new FormData()

        if (Array.isArray(payload.distrito)) {
          payload.distrito.forEach(row => {
            formData.append('sw017s02[]', row.id)
          })
        } else {
          formData.append('sw017s02', payload.distrito || '')
        }

        if (Array.isArray(payload.concelho)) {
          const sw017s03 = []
          payload.concelho.forEach(row => {
            sw017s03.push(row.id)
          })
          formData.append('sw017s03', sw017s03.join(';') || '')
        } else {
          formData.append('sw017s03', payload.concelho || '')
        }

        if (Array.isArray(payload.distrito) && Array.isArray(payload.concelho)) {
          formData.append('group', true)
        }

        api.post(`${apiConfig.url_base_api}freguesia/getAllByDConcelho`, formData)
          .then(response => {
            try {
              if (typeof response.data.freguesias === 'object') {
                commit('setFreguesias', response.data.freguesias)
                resolve(true)
              } else {
                reject(new Error(i18n.t('Problema ao carregar as freguesias')))
              }
            } catch (error) {
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                reject(new Error(i18n.t('Problema ao carregar as freguesias')))
              }
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao carregar as freguesias')))
            }
          })
      })
    },

    // Obter ruas da freguesia
    async getAllRuasByFreguesia({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const formData = new FormData()
        formData.append('sw017s02', payload.distrito || '')
        formData.append('sw017s03', payload.concelho || '')
        formData.append('sw017s22', payload.freguesia || '')
        formData.append('sw017s16_17', payload.codigoPostal || '')

        api.post(`${apiConfig.url_base_api}freguesia/getAllRuasByFreguesia`, formData)
          .then(response => {
            try {
              if (typeof response.data.ruasCodigoPostal === 'object') {
                commit('setRuas', response.data.ruasCodigoPostal)
                resolve(true)
              } else {
                reject(new Error(i18n.t('Problema ao carregar as ruas da freguesia')))
              }
            } catch (error) {
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                reject(new Error(i18n.t('Problema ao carregar as ruas da freguesia')))
              }
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao carregar as ruas da freguesia')))
            }
          })
      })
    },

    // Obter informação pelo codigo postal
    async fillLocalizationByCodePostal({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const formData = new FormData()
        formData.append('cod_postal', payload.codigoPostal || '')

        api.post(`${apiConfig.url_base_api}freguesia/fillDistConcFregByCodPostal`, formData)
          .then(response => {
            try {
              if (typeof response.data === 'object') {
                if (response.data.sel_distritos && response.data.sel_concelhos && response.data.sel_freguesia) {
                  if (typeof response.data.distritos === 'object') {
                    commit('setDistritos', response.data.distritos)
                  }

                  if (typeof response.data.concelhos === 'object') {
                    commit('setConcelhos', response.data.concelhos)
                  }

                  if (typeof response.data.freguesias === 'object') {
                    commit('setFreguesias', response.data.freguesias)
                  }

                  if (typeof response.data.ruasCodigoPostal === 'object') {
                    commit('setRuas', response.data.ruasCodigoPostal)
                  }

                  resolve({
                    distrito: response.data.sel_distritos,
                    concelho: response.data.sel_concelhos,
                    freguesia: response.data.sel_freguesia,
                    rua: ((typeof response.data.sel_rua === 'string') ? response.data.sel_rua : ''),
                  })
                } else {
                  reject(new Error(i18n.t('Problema ao obter a localização do código postal')))
                }
              } else {
                reject(new Error(i18n.t('Problema ao obter a localização do código postal')))
              }
            } catch (error) {
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                reject(new Error(i18n.t('Problema ao obter a localização do código postal')))
              }
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao obter a localização do código postal')))
            }
          })
      })
    },

    // Obter a lista de andares
    async getAllFloors({ commit }) {
      return new Promise((resolve, reject) => {
        api.post(`${apiConfig.url_base_api}localization/getAllFloors`)
          .then(response => {
            try {
              if (typeof response.data.floors === 'object') {
                commit('setFloors', response.data.floors)
                resolve(true)
              } else {
                reject(new Error(i18n.t('Problema ao carregar os andares')))
              }
            } catch (error) {
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                reject(new Error(i18n.t('Problema ao carregar os andares')))
              }
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao carregar os andares')))
            }
          })
      })
    },

  },
}
