var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Código Postal')}},[(_vm.view===false)?_c('b-input-group',[_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.tokenMaskCodigoPostal),expression:"tokenMaskCodigoPostal"}],attrs:{"autocomplete":"off"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.getFillLocalizationByCodePostal($event)}},model:{value:(_vm.localization.codigoPostal),callback:function ($$v) {_vm.$set(_vm.localization, "codigoPostal", $$v)},expression:"localization.codigoPostal"}}),_c('b-input-group-append',[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"outline-secondary","size":"sm"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.getFillLocalizationByCodePostal($event)}}},[_vm._v(" "+_vm._s(_vm.$t('Obter morada e mapa'))+" ")])],1)],1):_c('p',{staticClass:"text-primary mb-0"},[_vm._v(" "+_vm._s(_vm.localization.codigoPostal || '-----')+" ")])],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Distrito')}},[(_vm.view===false)?_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.distritos,"append-to-body":"","calculate-position":_vm.withPopper,"loading":_vm.loader.distrito,"label":"desc","item-text":"desc","item-value":"id"},on:{"input":_vm.getConcelhosByDistrito,"change":_vm.getConcelhosByDistrito},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var desc = ref.desc;
return [_vm._v(" "+_vm._s(desc)+" ")]}}],null,false,4124409017),model:{value:(_vm.localization.distrito),callback:function ($$v) {_vm.$set(_vm.localization, "distrito", $$v)},expression:"localization.distrito"}},[_c('div',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(" "+_vm._s(_vm.$t('Nenhum distrito'))+" ")])]):_c('p',{staticClass:"text-primary mb-0"},[_vm._v(" "+_vm._s(_vm.renderObjToTxt(_vm.localization.distrito, 'desc'))+" ")])],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Concelho')}},[(_vm.view===false)?_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.concelhos,"append-to-body":"","calculate-position":_vm.withPopper,"loading":_vm.loader.concelho,"label":"desc","item-text":"desc","item-value":"id"},on:{"input":_vm.getFreguesiasByConcelho,"change":_vm.getFreguesiasByConcelho},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var desc = ref.desc;
return [_vm._v(" "+_vm._s(desc)+" ")]}}],null,false,4124409017),model:{value:(_vm.localization.concelho),callback:function ($$v) {_vm.$set(_vm.localization, "concelho", $$v)},expression:"localization.concelho"}},[(_vm.localization.distrito)?_c('div',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(" "+_vm._s(_vm.$t('Nenhum concelho'))+" ")]):_vm._e(),(!_vm.localization.distrito)?_c('div',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(" "+_vm._s(_vm.$t('Escolha um distrito'))+" ")]):_vm._e()]):_c('p',{staticClass:"text-primary mb-0"},[_vm._v(" "+_vm._s(_vm.renderObjToTxt(_vm.localization.concelho, 'desc'))+" ")])],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Freguesia')}},[(_vm.view===false)?_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.freguesias,"append-to-body":"","calculate-position":_vm.withPopper,"loading":_vm.loader.freguesia,"label":"desc","item-text":"desc","item-value":"id"},on:{"input":_vm.getRuasByFreguesia,"change":_vm.getRuasByFreguesia},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var desc = ref.desc;
return [_vm._v(" "+_vm._s(desc)+" ")]}}],null,false,4124409017),model:{value:(_vm.localization.freguesia),callback:function ($$v) {_vm.$set(_vm.localization, "freguesia", $$v)},expression:"localization.freguesia"}},[(_vm.localization.concelho)?_c('div',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(" "+_vm._s(_vm.$t('Nenhuma freguesia'))+" ")]):_vm._e(),(!_vm.localization.concelho)?_c('div',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(" "+_vm._s(_vm.$t('Escolha um concelho'))+" ")]):_vm._e()]):_c('p',{staticClass:"text-primary mb-0"},[_vm._v(" "+_vm._s(_vm.renderObjToTxt(_vm.localization.freguesia, 'desc'))+" ")])],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Zona')}},[(_vm.view===false)?_c('b-form-input',{attrs:{"autocomplete":"off"},model:{value:(_vm.localization.zona),callback:function ($$v) {_vm.$set(_vm.localization, "zona", $$v)},expression:"localization.zona"}}):_c('p',{staticClass:"text-primary mb-0"},[_vm._v(" "+_vm._s(_vm.localization.zona || '-----')+" ")])],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Rua')}},[(_vm.view===false)?_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.ruas,"append-to-body":"","calculate-position":_vm.withPopper,"loading":_vm.loader.rua,"label":"rua","item-text":"rua","item-value":"id"},on:{"input":_vm.setRuaManual,"change":_vm.setRuaManual},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var rua = ref.rua;
return [_vm._v(" "+_vm._s(rua)+" ")]}}],null,false,3611536761),model:{value:(_vm.localization.rua),callback:function ($$v) {_vm.$set(_vm.localization, "rua", $$v)},expression:"localization.rua"}},[_c('div',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(" "+_vm._s(_vm.$t('Nenhuma rua'))+" ")])]):_c('p',{staticClass:"text-primary mb-0"},[_vm._v(" "+_vm._s(_vm.renderObjToTxt(_vm.localization.rua, 'rua'))+" ")])],1)],1)],1),(_vm.showRuaManual===true)?_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Rua')}},[(_vm.view===false)?_c('b-form-input',{attrs:{"autocomplete":"off"},model:{value:(_vm.localization.ruaManual),callback:function ($$v) {_vm.$set(_vm.localization, "ruaManual", $$v)},expression:"localization.ruaManual"}}):_c('p',{staticClass:"text-primary mb-0"},[_vm._v(" "+_vm._s(_vm.localization.ruaManual || '-----')+" ")])],1)],1)],1):_vm._e(),_c('b-row',[_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Nº de porta')}},[(_vm.view===false)?_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:(['##########']),expression:"['##########']"}],attrs:{"autocomplete":"off"},model:{value:(_vm.localization.numeroPorta),callback:function ($$v) {_vm.$set(_vm.localization, "numeroPorta", $$v)},expression:"localization.numeroPorta"}}):_c('p',{staticClass:"text-primary mb-0"},[_vm._v(" "+_vm._s(_vm.localization.numeroPorta || '-----')+" ")])],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Andar')}},[(_vm.view===false)?_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.floors,"append-to-body":"","calculate-position":_vm.withPopper,"loading":_vm.loader.andar,"label":"desc","item-text":"desc","item-value":"id"},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var desc = ref.desc;
return [_vm._v(" "+_vm._s(desc)+" ")]}}],null,false,4124409017),model:{value:(_vm.localization.andar),callback:function ($$v) {_vm.$set(_vm.localization, "andar", $$v)},expression:"localization.andar"}},[_c('div',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(" "+_vm._s(_vm.$t('Sem resultados'))+" ")])]):_c('p',{staticClass:"text-primary mb-0"},[_vm._v(" "+_vm._s(_vm.renderObjToTxt(_vm.localization.andar, 'desc'))+" ")])],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Divisão')}},[(_vm.view===false)?_c('b-form-input',{attrs:{"autocomplete":"off"},model:{value:(_vm.localization.divisao),callback:function ($$v) {_vm.$set(_vm.localization, "divisao", $$v)},expression:"localization.divisao"}}):_c('p',{staticClass:"text-primary mb-0"},[_vm._v(" "+_vm._s(_vm.localization.divisao || '-----')+" ")])],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Ajuda de morada')}},[(_vm.view===false)?_c('b-form-input',{attrs:{"autocomplete":"off"},model:{value:(_vm.localization.ajudaMorada),callback:function ($$v) {_vm.$set(_vm.localization, "ajudaMorada", $$v)},expression:"localization.ajudaMorada"}}):_c('p',{staticClass:"text-primary mb-0"},[_vm._v(" "+_vm._s(_vm.localization.ajudaMorada || '-----')+" ")])],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Nome de edifício')}},[(_vm.view===false)?_c('b-form-input',{attrs:{"autocomplete":"off"},model:{value:(_vm.localization.nomeEdificio),callback:function ($$v) {_vm.$set(_vm.localization, "nomeEdificio", $$v)},expression:"localization.nomeEdificio"}}):_c('p',{staticClass:"text-primary mb-0"},[_vm._v(" "+_vm._s(_vm.localization.nomeEdificio || '-----')+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }